import { Helmet } from "react-helmet";
import React from "react";
import Header from "components/layout/Header";
import Footer from "components/layout/Footer";

import "assets/scss/style.scss";

const LayoutDefault = ({ children }) => (
  <React.Fragment>
    <Helmet bodyAttributes={{ class: "has-animations is-loaded" }}>
      <title>Immersion XP</title>
      <link rel="shortcut icon" href="/images/favicon.png" />
      <meta property="og:image" content="/images/og-image.png" />
      <meta
        name="description"
        content="| Learn a language through fun, affordable, bite-size
        immersion experiences"
      />
      <meta
        property="og:description"
        content="| Learn a language through fun, affordable, bite-size
        immersion experiences"
      />
    </Helmet>
    <Header
      navPosition="right"
      className="reveal-from-top"
      hideSignin
      hideNav
    />
    <main className="site-content">{children}</main>
    <Footer />
  </React.Fragment>
);

export default LayoutDefault;
